<template>
  <div style="height: 100vh;">
    <not-found v-if="err !== ''" />
  </div>
</template>

<script>
import ShortenerUseCase from './app/usecase';
import NotFound from '../not-found/NotFound';
import mixinMetaInfo from '../../misc/mixinMetaInfo';

export default {
  name: 'ShortenerLink',
  components: { NotFound },
  mixins: [mixinMetaInfo],
  data() {
    return {
      searchMetaInfo: '_link',
      err: '',
    };
  },
  beforeCreate() {
    if (!this.isSsr()) {
      const pathName = window.location || document.URL;
      ShortenerUseCase.getShortenerLink(pathName)
        .then((resp) => {
        /** @type {ShortenerLinkResponse} */
          const { data } = resp;
          if (data.original_url !== '') {
            window.location = data.original_url;
          } else {
            this.err = 'Not found';
          }
        })
        .catch((err) => {
          this.err = err.err;
        });
    }
  },
};
</script>
