<template>
  <div>
    <div
      v-if="isFromShop"
      class="base-not-found"
    >
      <span style="margin-bottom: 2%;">Apakah anda ingin ke BisaBelanja?</span>
      <Button
        id="button-mobile"
        :data-url="originalLink"
        text="Buka Sekarang"
        @click="clickMeMobile"
      />
      <Button
        id="button-desktop"
        :data-url="originalLink"
        text="Download"
        @click="clickMe"
      />
    </div>
    <div
      v-else
      class="base-not-found"
    >
      <div class="base-not-found__not-found-img">
        <ImageLazy
          :src="'/images/merchant/merchant-not-active.svg'"
          alt="page-not-found.png"
        />
      </div>
      <span class="not-found-description"> Halaman yang Anda Cari Tidak Ditemukan</span>
      <Button
        id="button-goto-home"
        text="ke Halaman Utama"
        @click="goToHomePage"
      />
    </div>
  </div>
</template>

<script>
import Button from '@/components/new-button/Button';
import ImageLazy from '@/components/ImageLazy/index.vue';

export default {
  name: 'NotFound',
  components: { Button, ImageLazy },
  data() {
    return {
      isFromShop: false,
      link: 'https://play.google.com/store/apps/details?id=com.lionparcel.services.consumer&hl=in',
      isiOs: false,
      originalLink: this.isSsr() ? '' : document.URL,
    };
  },
  created() {
    this.isFromShop = this.$route.path.split('/')[1] === 'shop';
    this.setOSLink();
    this.setOriginalLink();
    this.isDownloadApp();
  },
  methods: {
    clickMeMobile() {
      if (this.isiOs) {
        window.location = `lionparcel:/${this.$route.path}`;
        setTimeout(() => {
          window.location = this.originalLink;
        }, 5000);
      } else {
        window.location = this.originalLink;
      }
    },
    clickMe() {
      window.open(this.link, '_blank');
    },
    setOSLink() {
      if (this.isSsr()) return;
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;

      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        this.isiOs = true;
        this.link = 'https://apps.apple.com/id/app/lion-parcel/id1455977134';
      }
    },
    isDownloadApp() {
      if (this.$route.fullPath.includes('download')) {
        window.location = this.link;
      }
    },
    setOriginalLink() {
      if (this.$route.query.invitedby) {
        this.originalLink = this.link;
      }
    },
    goToHomePage() {
      this.$router.replace('/');
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/style.scss";
.base-not-found {
  padding: 0 40px;
  height: 80vh;
  text-align: center;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;

  &__not-found-img {
    width: 224px;
    height: 190px;

    & ::v-deep .skeleton-box {
      width: 100%;
      height: 100%;
    }
  }
}

#button-mobile {
  display: none;
}

#button-desktop {
  display: block;
}

#button-goto-home {
  margin-top: 21px;

  @include for-size(mobile) {
    width: 100%;
  }
}

.not-found-description {
  margin-top: 2%;
  color: $color-base-text;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
}

@media (max-width: 768px) {
  #button-mobile {
    display: block;
  }

  #button-desktop {
    display: none;
  }
}
</style>
