import ShortenerLinkRepository from '../api';
import '../types';

class ShortenerLinkUseCase {
  constructor() {
    /** @type {ShortenerLinkRepository} */
    this.api = new ShortenerLinkRepository();
  }

  async getShortenerLink(pathName) {
    const query = '?url=';
    const { data, err } = await this.api.getShortener(query + pathName);
    return { data, err };
  }
}

export default new ShortenerLinkUseCase();
