import CustomerAppAPI from '@/app/customerAppAxios';
import '../types';

/** @borrows CustomerAppAPI */
export default class MerchantRepository extends CustomerAppAPI {
  constructor() {
    super();
    /** @type {string} */
    this.endpoint = '/v1/shop/url-shortener';
  }

  /**
     *
     * @param queryParam {string}
     * @returns {Promise<{data: ShortenerLinkResponse, err: null}|{data: null, err: string | null}>}
     */
  async getShortener(queryParam) {
    try {
      /** @type {AxiosResponse<Object>} */
      const res = await this.$axios.get(`${this.endpoint}${queryParam}`);
      if (res.status === 200) {
        if (res.data) {
          return { data: res.data, err: null };
        }
      }
      return { data: null, err: null };
    } catch (err) {
      return { data: null, err: this.setErrorMessage(err) };
    }
  }
}
